@import "~antd/dist/antd.less";

.ant-collapse-content-box {
  padding: 0 !important;
}

.cover-image:hover {
  object-fit: cover;
}

.ant-upload.ant-upload-drag .ant-upload {
  padding: 0 !important;
}

.custom-drag-handle {
  cursor: move;
  width: 100%;
  height: 32px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: #dad5e3;
  // top border radius
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  // flex
  display: flex;
  align-items: center;
  justify-content: center;
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

.file-upload-list {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  margin-top: 8px;
  // padding: 4px;
  border-radius: 4px;
}

.file-upload-list:hover {
  // background-color: #eeeaf5;
}

.ant-avatar {
  background-color: #001529;
}

@primary-color: #4D38A3;@link-color: #4D38A3;@border-radius-base: 6px;